import React from 'react'
/* import { Route } from 'react-router' */
import logo from './stokify-logo.png'
import './App.css'

const App: React.FC = () => {
	return (
		<div className='App'>
			<div className='logo-container'>
				<img src={logo} className='stokify-logo' />
				<div className='under-const-text'>
					Stokify.com is coming soon. Stay tuned!
				</div>
			</div>
		</div>
	)
}

export default App
